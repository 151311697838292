import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import SquareIcon from '../../../img/square_icon.png'
import { useTranslation } from 'react-i18next'
import { Box, useMediaQuery } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCityConfig } from '../../../actions/ui'
import useQuery from '../../../hooks/useQuery'
import {
  REACT_APP_HOST,
  REACT_APP_HARDCODED_FAVICON,
  REACT_APP_HARDCODED_TITLE,
  REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
  REACT_APP_APP_NAME
} from '../../../constants/config'
import { SmartTopBanner } from '../../ui'

export const Layout = ({
  children,
  title,
  description
}) => {
  const { t } = useTranslation()
  const { cityId = null } = useParams()
  const dispatch = useDispatch()
  const query = useQuery()

  const mobile = useMediaQuery('(max-width:480px)')
  const user = useSelector(state => state?.user?.auth)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const openTopSmartBanner = useSelector(state => state?.ui?.components.openTopSmartBanner)

  useEffect(() => {
    if (!user || cityConfig) return
    dispatch(setCityConfig(userPosition, query.has('ciudad_id') ? query.get('ciudad_id') : cityId))
  }, [user])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100dvh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'auto auto'
      }}
    >
      <Helmet>
        <title>{title || REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: REACT_APP_APP_NAME })}</title>
        <meta
          name='title'
          content={title || REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: REACT_APP_APP_NAME })}
        />
        <meta
          name='theme-color'
          content={REACT_APP_PALLET_PRIMARY_MAIN_COLOR}
        />
        <meta
          name='description'
          content={description || t('metadata.description')}
        />
        <link rel='alternate' hrefLang='en' href={`${REACT_APP_HOST}/?hl=en`} />
        <link rel='alternate' hrefLang='es' href={`${REACT_APP_HOST}/?hl=es`} />
        <link rel='alternate' hrefLang='es-CL' href={`${REACT_APP_HOST}/?hl=es-CL`} />
        <link rel='alternate' hrefLang='x-default' href={REACT_APP_HOST} />
        <link
          rel='icon'
          href={REACT_APP_HARDCODED_FAVICON || SquareIcon}
        />
        <link
          rel='apple-touch-icon'
          href={REACT_APP_HARDCODED_FAVICON || SquareIcon}
        />
        {/* Open Graph / Facebook */}
        <meta
          property='og:title'
          content={title || REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: REACT_APP_APP_NAME })}
        />
        <meta
          property='og:description'
          content={description || t('metadata.description')}
        />
        {/* <meta property="og:image" content=""/> */}
        {/* Twitter */}
        <meta
          property='twitter:title'
          content={title || REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: REACT_APP_APP_NAME })}
        />
        <meta
          property='twitter:description'
          content={description || t('metadata.description')}
        />
        {/* <meta property="twitter:image" content=""/> */}
      </Helmet>
      {openTopSmartBanner && mobile && cityConfig && <SmartTopBanner />}
      {children}
    </Box>
  )
}
