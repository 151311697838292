import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {
  REACT_APP_HARDCODED_DESCRIPTION_EN,
  REACT_APP_HARDCODED_DESCRIPTION_ES,
  REACT_APP_HARDCODED_DESCRIPTION_ES_CL,
  REACT_APP_APP_NAME
} from './constants/config'
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      lookupQuerystring: 'hl'
    },
    debug: false,
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          learn_more: 'Learn more',
          metadata: {
            description: REACT_APP_HARDCODED_DESCRIPTION_EN || 'How to get there, lines, routes, stops, schedules, balance and chat with the community of users of buses, subways, trains and all public transport.',
            title: '{{company}} - Routes and schedules of buses, subways and trains'
          },
          home: {
            menu: {
              title: 'You are in {{prop1}}', // "you_are_here"
              change_city: 'Change city', // "change_city_label"
              items: {
                our_company: 'Our company', // "our_company"
                become_a_contributor: 'Become a contributor', // "become_a_contributor"
                blog: `${REACT_APP_APP_NAME} blog`,
                your_city: 'Your city',
                send_feedback: 'Send comments', // "comments_and_suggestions"
                send_feedback_title: 'What could we improve?', // "send_rate_title"
                send_feedback_description: 'If you want to receive a response, you must log in'
              },
              download_app: 'Download app!',
              copyright: `©${new Date().getFullYear()} {{company}}, all rights reserved.`,
              privacy_policies: 'Privacy policies',
              terms_and_conditions: 'Terms and conditions', // "tos_description"
              send_feedback_title: 'What could we improve?', // "send_rate_title"
              send_feedback_thanks_title: 'Thank you very much!',
              send_feedback_thanks_content: 'Your opinion is important to us',
              send_feedback_error_title: 'An error has occurred',
              send_feedback_error_content: "We couldn't send your comment"
            },
            navbar: {
              items: {
                search: 'Search',
                lines: 'Lines', // home_shortcut_title_lines
                card_refill: 'Recharge', // "card_refill"
                info_uppercase: 'Useful info', // info_uppercase
                alerts: 'Alerts',
                alt_info_uppercase: 'Useful information',
                alt_alerts: 'Alerts and news',
                share: 'Share'
              }
            },
            change_country: {
              title: 'Choose your country', // "choose_your_country"
              autocomplete: {
                placeholder: 'Search'
              },
              alert: 'You must select a country to continue.'
            },
            change_city: {
              title: 'Choose your city', // "choose_your_city"
              autocomplete: {
                placeholder: 'Search'
              },
              alert: 'You must select a city to continue.'
            },
            filters: {
              title: 'What do you want to see on the map?',
              items: {
                refill_points: 'Refill points',
                user_reports: 'User reports',
                network_map: 'Network map'
              }
            },
            places: {
              home: 'Home', // "home"
              work: 'Work', // "work"
              add_home: 'Add home',
              add_work: 'Add work',
              add_favorite_place: 'Add favorite place'
            },
            origin_or_destination: 'Origin or destination?', // "origin_or_destination"
            dialog_destination_title: 'Select a destination or starting point', // "dialog_destination_title"
            from_here: 'From here', // "from_here"
            to_here: 'To here', // "to_here"
            short_from_here: 'From', // "from_here"
            short_to_here: 'To', // "to_here"
            searching: 'Searching...'
          },
          lines: {
            real_time: 'Real time',
            header: {
              title: 'All lines'
            },
            autocomplete: {
              placeholder: 'Search',
              alternative_placeholder: 'Search a line'
            },
            favorites: {
              title: 'Favorites'
            },
            transport_types: {
              all: 'All'
            },
            helper_text: '🤔 Try another search term',
            add_to_favorites: 'Add to favorites',
            line_without_delays: 'Line without delays!',
            line_with_delays: 'This line may present delays',
            max_visible_vehicles_onboarding: {
              title: 'Next arrivals',
              description: 'This line has real-time information, you can view next arrivals by selecting a stop on the route.',
              button: 'Understood'
            }
          },
          send: 'Send',
          hint_location_A: "What's your starting point?", // hint_location_A
          hint_location_B: 'Where do you want to go?', // hint_location_B
          my_location: 'My location', // "my_location"
          search: {
            my_location: 'My location', // "my_location"
            pick_on_map: 'Pick on map', // "autocomplete_pick_on_map"
            help: 'Start by searching for your places or destinations. Recent searches will appear here.',
            super_search: "Can't find the place?", // "super_search"
            recent: 'Recent', // recent
            delete_history: 'Delete history'
          },
          choose_from_map: {
            select_location: 'Choose a location', // "select_location"
            confirm: 'OK',
            favorite_place_success_title: 'Favorite place added',
            favorite_place_success_message: 'You can access it from the home screen',
            favorite_place_error_title: 'An error has occurred',
            favorite_place_error_message: "We couldn't add your favorite place",
            favorite_place_name_form_title: 'New favorite place',
            favorite_place_name_form_label: 'Name',
            favorite_place_cancel: 'Cancel',
            add_favorite_place: 'Add favorite place',
            favorite_place_confirm: 'Save'
          },
          search_preview: {
            title: 'Confirm search', // "previous_search_title_confirm_search"
            alt_title: 'Filter search',
            arrival_time_first_option: 'Leave now', // "arrival_time_first_option"
            arrival_time_second_option: 'Arrive at…', // "arrival_time_second_option"
            search: 'Search', // "search"
            filters: {
              title: 'Search filters', // "filter_search"
              items: {
                walk: 'Walk', // "walk"
                blocks: 'Blocks', // "blocks"
                type_route: 'Type of route', // "type_route"
                transfers: { // "transfers_array"
                  best_route: 'Best route',
                  only_directs: 'Only directs',
                  only_transfers: 'Only transfers'
                },
                transport_types: 'Transport types', // "transport_types"
                filters: 'Filters',
                remember_changes: 'Remember for future searches'
              },
              apply_and_search: 'Apply and search' // "apply_and_search"
            }
          },
          routes: {
            line_route: 'Line {{prop1}}', // "line_route"
            choose_direction: 'Choose direction', // "choose_direction"
            see_times: 'See schedules', // "see_times"
            times: 'Schedules', // "times"
            data_provider: 'Data provided by {{prop1}}',
            data_source_notes: 'Routes, stops and schedules info may be altered due to roadblocks, weather conditions, traffic and other external factors. We recommend to take this in mind before planning your trip.', // "data_source_notes"
            reported_by: 'Reported by GPS {{prop1}} ago',
            share: 'Share',
            send_to_mobile: 'Send to phone'
          },
          invitation_message: {
            title: 'You discovered a new feature!',
            content: 'Download the app and try it out!',
            ios_content: 'We are working on this new feature 🚀',
            download: 'Download app',
            link_error_message: 'An error occurred. Please try again later.'
          },
          news: {
            title: 'Alerts and news',
            news: {
              title: 'News',
              see_new: 'See full new',
              load_more_news: 'Load more news',
              show_less: 'Show less'
            },
            alerts: {
              title: 'Alerts',
              possible_affected_routes: 'Possible affected routes', // "possible_affected_routes"
              load_more_alerts: 'Load more alerts',
              show_less: 'Show less'
            },
            creation_date: '{{prop1}} by ',
            see: 'See',
            cancel: 'Cancel',
            no_results: 'No results found'
          },
          share: {
            title: 'Share',
            link: 'Link',
            social: 'Social',
            copy_link: 'Copy link',
            copied: 'Copied',
            visualize_this_trip: 'Visualize this trip in the link.',
            visualize_this_search: 'View this search.',
            user_has_sent_you_a_search: '{{prop1}} has sent you a search!',
            they_sent_you_a_quest: "You've been sent a quest!",
            view_this_search: 'View this search',
            search_from_to: 'Search from {{prop1}} to {{prop2}}',
            share_stop_preview_title: 'Check this stop arrivals' // "share_stop_preview_title"
          },
          share_route: 'Share route',
          share_with_wpp: 'Share with WhatsApp',
          send_to_mobile: {
            title: 'Send to phone',
            step_1: '1. Open the camera of your mobile device and point the camera at the QR Code.',
            step_2: '2. Focus to be recognized.',
            step_3: `3. Click on the notification and that's it: Enjoy ${REACT_APP_APP_NAME} on your mobile!`,
            help: "Can't scan the code? ",
            see_more_options: 'Login and know the other options',
            address_prompt: 'Address: {{address}}' // "address_prompt"
          },
          send_by_wpp: 'Send by WhatsApp',
          stop: {
            stop_details_tab_title: 'Stop', // "stop_details_tab_title"
            stop_details_status_tab_title: 'Status', // "stop_details_status_tab_title"
            not_reports: 'This stop does not present reports', // "not_reports" // TODO: review, in android This %1$s does not present reports
            report: 'Report', // "report"
            scheduled: 'Scheduled', // "scheduled"
            see_times: 'Schedules', // "see_times"
            arrival_time_info_green: 'ETA is using a real-time vehicle location. Precision may vary depending on the GPS signal or trip anomalies.', // "arrival_time_info_green"
            arrival_time_info_yellow: 'ETA is predicted using the provided info by transportation authorities. This is regularly updated and may not be exact.', // "arrival_time_info_yellow"
            understood: 'Understood', // "understood"
            take_picture: 'Take photo', // "take_picture"
            more_pictures: 'More photos', // "more_pictures"
            arrivals: {
              title: 'Next arrivals', // "next_arrivals"
              arriving: 'Arriving...' // "arriving"
            },
            options: {
              stop_details_option_show_in_street_view: 'Show in Street View', // "stop_details_option_show_in_street_view"
              stop_details_option_add_picture: 'Add photo', // "stop_details_option_add_picture"
              edit: 'Edit', // "edit"
              report: 'Report', // "report"
              share: 'Share' // "share"
            },
            stop_secure: 'Is this place safe?', // "stop_secure"
            arrival_times_text: 'How are we getting the ETA?', // "arrival_times_text"
            arrivals_unavailable: 'Arrivals for this stop are currently unavailable',
            watch: 'Check', // "watch"
            no: 'No', // "no"
            yes: 'Yes', // "yes"
            why: 'Why?', // "why"
            estimated_occupancy: 'Estimated occupancy', // "estimated_occupancy"
            occupancy_dialog_desc_2: 'You can share the occupancy to help your community launching your live trips', // "occupancy_dialog_desc_2"
            occupancy_dialog_desc_3: 'Estimated crowd level for this arrival', // "occupancy_dialog_desc_3"
            feedback: {
              bar_title: 'Is there any problem with this stop?',
              result_detail_button_text: 'Is there any problem with this stop?',
              form_send_button_text: 'Send feedback'
            }
          },
          stop_times: {
            day_tabs: {
              week_days: 'Weekdays',
              saturday: 'Saturday',
              sunday_and_holydays: 'Sunday and Holydays'
            },
            share: {
              title: `Plan your trip on ${REACT_APP_APP_NAME}`,
              description: 'Schedules of the route {{tripHeadSign}} for the stop {{stopName}}',
              short_title: 'Share stop',
              short_description: `Check this stop out on ${REACT_APP_APP_NAME}`
            }
          },
          user: {
            my_account: 'My account',
            level: 'Level', // "level",
            scores: 'Points', // "scores"
            ranking: 'Ranking', // "ranking"
            missions: 'Missions', // "missions"
            my_avatar: 'My avatar', // "my_avatar"
            settings: 'Settings',
            no_recent_activity: 'No recent activity found',
            recent_activity: 'Recent activity', // "recent_activity"
            personal_information: {
              title: 'Personal information',
              account_data_title: 'Account data',
              email: 'E-mail',
              user: 'User',
              personal_data_title: 'Personal data',
              name_and_surname: 'Name and surname',
              telephone: 'Telephone',
              gender: 'Gender',
              genders: {
                male: 'Male',
                female: 'Female',
                prefer_not_to_say: 'I prefer not to say'
              }
            },
            options: {
              become_a_contributor: 'Become a contributor', // "become_a_contributor"
              why_sum_points: 'Why to sum points', // "why_sum_points"
              sign_out: 'Logout', // "sign_out"
              delete_account: 'Delete account' // "delete_account"
            }
          },
          logout_confirmation: {
            title: 'Are you sure you want to logout?', // "logout_confirmation",
            no: 'No', // "no"
            yes: 'Yes' // "yes"
          },
          delete_account_first_confirmation: {
            title: `You're going to delete your ${REACT_APP_APP_NAME} account. Are you sure?`, // "delete_account_first_confirmation",
            no: 'No',
            yes: 'Yes'
          },
          delete_account_second_confirmation: {
            title: 'If you agree to delete your account, the record of points obtained for collaborating with the Community and each of your contributions will be definitively deleted. They will also delete your favorite lines, stops and places.', // "delete_account_second_confirmation",
            no: 'Cancel',
            yes: 'Accept'
          },
          delete_account_failure: {
            title: 'Error deleting account', // "delete_account_failure",
            message: 'An error occurred while deleting your account. Please try again later.' // "message"
          },
          deep_links: {
            title: 'Download the app!',
            content: `${REACT_APP_APP_NAME} is the collaborative app you need to get around your city more easily, quickly and safely by public transport or any alternative to the car.\n' + 'Find the routes, routes, stations and stops, timetables and delays of all lines of your city.`,
            download: 'Download'
          },
          login: {
            title: 'Sign in or create an account',
            social_button_title: 'Sign in with {{prop1}}',
            phone_button_title: 'Sign in with phone',
            email_button_title: 'Sign in with email',
            phone_number_title: 'Enter your phone number',
            phone_number_input_label: 'Phone',
            continue_button: 'Continue',
            cancel_button: 'Cancel',
            code_verification_title: 'Enter the code sent to {{prop1}}',
            countdown_text: 'Resend code in {{prop1}} seconds',
            resend_code_button: 'Resend code',
            resend_code_success_alert_title: 'Code sent to {{prop1}}',
            name_form_title: 'Enter your name',
            name_input_label: 'Name',
            email_input_label: 'E-mail',
            email_is_required: 'E-mail is required',
            password_is_required: 'Password is required',
            passwords_not_match: 'Passwords do not match',
            name_is_required: 'Name is required',
            input_default_error: 'Review the information entered',
            first_password_input_label: 'Password',
            second_password_input_label: 'Confirm password',
            create_account_button: 'Create account',
            accept: 'By continuing, you confirm that you accept our ',
            terms_and_conditions: 'Terms and Conditions ',
            and_our: 'and our ',
            privacy_policy: 'Privacy Policy',
            email_not_verified: 'Your email is not verified.',
            resend_email: 'Resend email',
            email_verification_success_title: 'Email verified',
            email_verification_success_message: 'Your email has been verified successfully',
            email_verification_failure_title: 'Error verifying email',
            email_verification_failure_message: 'An error occurred while verifying your email. Please try again later.',
            failure_login_title: 'Error signing in',
            failure_login_message: 'An error occurred while signing in. Please try again later.',
            fee_warning: 'Charges may apply to your mobile device account.',
            password_min_length: 'Password must be at least 6 characters long',
            modal: {
              title: 'Login', // "login"
              login_benefits_1: 'Seems like this feature requires login. ', // "login_benefits"
              login_benefits_2: 'You will get access to all features, receive customized alerts and we will keep your data in sync.', // "login_benefits"
              later: 'Later' // "later"
            }
          },
          trip_share: {
            results: 'Routes and trips', // "results"
            recommended_routes_title: 'Recommended routes', // "recommended_routes_title"
            search_result_fare_title: 'Price', // "search_result_fare_title"
            search_result_arrival_title: 'Arrival', // "search_result_arrival_title"
            search_result_crowded_average_title: 'Occupancy', // "search_result_crowded_average_title"
            button_load_more_search_result: 'Load more results', // "button_load_more_search_result"
            button_load_fewer_search_results: 'Show fewer results',
            car_result_distance_title: 'Distance', // "car_result_distance_title",
            available_title: 'Available',
            alternatives: 'Alternative transport', // "alternatives"
            passengers_on_board_title: 'Passengers on board',
            occupancy_item_title_1: 'Very few', // occupancy_item_title_1,
            occupancy_item_title_2: 'Few', // occupancy_item_title_2
            occupancy_item_title_3: 'Many', // occupancy_item_title_3
            occupancy_item_title_4: 'Full', // occupancy_item_title_4
            could_not_find_results: 'No results found\n' + 'Try changing your search preferences',
            feedback: {
              bar_title: 'Is something wrong with the results?',
              result_detail_button_text: 'Is something wrong with this trip?',
              form_send_button_text: 'Send feedback'
            },
            see_full_route: 'See full route',
            bike_station: 'Bike station',
            bikes: 'Bikes',
            places: 'Places',
            open: 'Open',
            walk_to: 'Walk to',
            you_are_aprox: 'You are aprox. {{prop1}} m away', // "you_are_aprox"
            wait_for: 'Wait for',
            go_forward: 'Go forward {{prop1}} stops',
            close_travel_mode_dialog_title: 'You didn\'t arrive yet!',
            close_travel_mode_dialog_content: 'Stay in travel mode to promote real-time predictions to other passengers',
            close_travel_mode_dialog_confirm_text: 'Exit',
            close_travel_mode_dialog_cancel_text: 'Stay',
            see_all_stops: 'See all stops',
            walk: 'Walk',
            trip_duration_title: 'Trip duration',
            moto_taxi: {
              title: 'The fastest route',
              call_to_action_button_text: 'Request',
              suggested_price: 'Suggested price',
              pick_up_drop_off_diferent_zones_warning: 'Pick up and drop off are in different zones',
              no_drop_off_zone_covered_warning: 'The drop off zone is not covered by the service',
              no_pick_up_zone_covered_warning: 'The pick up zone is not covered by the service'
            }
          },
          results: {
            start: 'Start', // "start"
            you_arrive_at: "🏁 You'll arrive at" // "you_arrive_at"
          },
          identify_city: {
            title: 'Seems like you are in {{prop1}}', // "change_city"
            content: 'Do you want to move to this city?', // "change_city_text"
            i_disagree: 'No, thanks', // "i_disagree"
            yes: 'Yes'
          },
          change_city: {
            title: 'Select your city'
          },
          covid: {
            attention_schedule: 'Attention schedule:',
            more_information: 'More information'
          },
          languages: {
            language: 'Language',
            english: 'English',
            spanish: 'Spanish {{country}}'
          },
          smart_banners: {
            full_page: {
              title: 'The only collaborative public transport App for your urban trips',
              subtitle: "Don't waste any more time!",
              app_dowloads: '({{prop1}} downloads)',
              download_app: 'Download app',
              continue_on_mobile_site: 'Continue on mobile site'
            },
            top: {
              title: 'Follow your trip in the App',
              open: 'Open'
            },
            bottom: {
              title: 'Easier with the App!',
              subtitle: 'The user community collaborates with your live trip',
              use_app: 'Use the app'
            }
          },
          loading: 'Loading...',
          feedback_on_boarding_modal: {
            title: `Are you satisfied with ${REACT_APP_APP_NAME} data?`,
            description_1: 'Your feedback is important to us. If anything went wrong let us know so we can improve your experience.',
            description_2: 'You can share your concerns with us every time you see a button with this icon:',
            button_text: 'Ok, got it!'
          },
          feedback_form: {
            button_text: 'Send feedback',
            general_error_message: 'Something went wrong. Please try again later.',
            no_questions_error_title: 'There was an error',
            no_questions_error_message: 'Try again later.'
          },
          feedback_success_modal: {
            title: 'Thank you for your feedback!',
            description: 'We will take it into account to improve the app.'
          },
          city_info: {
            no_results: 'No results found'
          },
          nps_survey: {
            title: 'How likely are you to recommend this trip planner to your friends or family?',
            low_label: 'Not at all likely',
            high_label: 'Very likely',
            text_area_placeholder: 'Why?',
            button_text: 'Send'
          },
          tickets_rewards_program: {
            nav_bar_button: 'Earn points',
            reward_tickets_page_title: 'Earn points',
            reward_tickets_page_hero_title: 'Earn with your travels',
            reward_tickets_page_hero_description: 'Upload your tickets every time you take a trip and participate for incredible prizes! In this launch, we are supporting the following companies:',
            uploaded_tickets: 'registered tickets',
            points: 'Points',
            redeem_points: 'Redeem points',
            add_new_ticket: 'Add new ticket',
            add_points: 'Add points',
            faq_title: 'Frequently asked questions',
            reward_tickets_validate_page_title: 'Add new ticket',
            validate_code: 'Validate code',
            select_company: 'Select company',
            select_price: 'Select price',
            select_company_error: 'You must select a company',
            select_price_error: 'You must select a price',
            select_ticket_number_error: 'You must enter a ticket number',
            select_image_error: 'You must upload an image',
            faqs: {
              question_1: 'How do I upload my ticket?',
              answer_1: 'Select “Add new ticket” and follow the instructions provided by the carrier to validate your ticket.',
              question_2: 'I can not upload my ticket',
              answer_2: 'Check that all the information fields are correctly filled in and make sure that you have not uploaded the ticket before.',
              question_3: 'My ticket is already uploaded',
              answer_3: 'If your ticket is listed as uploaded and it was not you, send us a photo of your ticket to contacto@treep.pe, commenting on the user and attaching a photo of your ticket.',
              question_4: 'How do I redeem my points?',
              answer_4: 'Each company will share which rewards they will deliver to their users. Start adding points as soon as you are one of the first to redeem them!'
            },
            reward_tickets_validate_page_hero_title: 'Find the number and price on your ticket',
            company_label: 'Company',
            price_label: 'Ticket price',
            ticket_number_label: 'Ticket number',
            example: 'Example',
            validation_component: {
              points_earned: 'You have earned {{points}} points',
              success_button: 'See my points',
              error_button: 'Go back to the form'
            },
            image_upload_component: 'Take a picture of your ticket or select a photo from your gallery',
            unsave_changes_confirmation_title: 'Unsaved Changes',
            unsave_changes_confirmation_content: 'Are you sure you want to leave this page? Your changes will be lost.',
            unsave_changes_confirmation_cancel_button: 'Cancel',
            unsave_changes_confirmation_leave_button: 'Leave',
            see_active: 'See active coupon',
            active_coupon: 'Active coupon',
            redeemed_coupon: 'Redeemed coupon'
          },
          otp_directions: {
            DEPART: 'Head {{absoluteDirection}} towards {{streetName}}',
            CONTINUE: 'Continue on {{streetName}}',
            LEFT: 'Turn left on {{streetName}}',
            SLIGHTLY_LEFT: 'Turn slightly left on {{streetName}}',
            HARD_LEFT: 'Turn sharp left on {{streetName}}',
            RIGHT: 'Turn right on {{streetName}}',
            SLIGHTLY_RIGHT: 'Turn slightly right on {{streetName}}',
            HARD_RIGHT: 'Turn sharp right on {{streetName}}',
            CIRCLE_CLOCKWISE: 'At the roundabout, take the {{exit}} exit towards {{streetName}}',
            CIRCLE_COUNTERCLOCKWISE: 'At the roundabout, take the {{exit}} exit towards {{streetName}}',
            ELEVATOR: 'Take the elevator and get off at {{streetName}}',
            UTURN_LEFT: 'Make a left u-turn onto {{streetName}}',
            UTURN_RIGHT: 'Make a right u-turn onto {{streetName}}',
            SOUTH: 'south',
            SOUTHWEST: 'southwest',
            SOUTHEAST: 'southeast',
            NORTH: 'north',
            NORTHEAST: 'northeast',
            NORTHWEST: 'northwest',
            WEST: 'west',
            EAST: 'east',
            1: 'first',
            2: 'second',
            3: 'third',
            4: 'fourth',
            5: 'fifth',
            6: 'sixth',
            7: 'seventh',
            8: 'eighth'
          },
          earn_options: {
            title: 'How to earn points',
            description: 'Learn how to score points with actions.',
            next_button: 'Next',
            back_button: 'Back',
            coming_soon: 'Coming soon'
          }
        }
      },
      es: {
        translation: {
          learn_more: 'Conocer más',
          metadata: {
            description: REACT_APP_HARDCODED_DESCRIPTION_ES || 'Cómo llegar, líneas, recorridos, paradas, horarios, saldo y chat con la comunidad de usuarios de colectivos, subtes, trenes y todo el transporte público.',
            title: '{{company}} - Recorridos y horarios de colectivos, subtes y trenes'
          },
          home: {
            menu: {
              title: 'Estás en {{prop1}}', // "you_are_here"
              change_city: 'Cambiar de ciudad', // "change_city_label"
              items: {
                our_company: 'Nuestra compañía', // "our_company"
                become_a_contributor: 'Convertirme en colaborador', // "become_a_contributor"
                blog: `Blog ${REACT_APP_APP_NAME}`,
                your_city: 'Tu ciudad',
                send_feedback: 'Enviar comentarios', // "comments_and_suggestions"
                send_feedback_title: '¿Qué podríamos mejorar?', // "send_rate_title"
                send_feedback_description: 'Si deseas recibir una respuesta, debes iniciar sesión'
              },
              download_app: '¡Descargate la app!',
              copyright: `©${new Date().getFullYear()} ${REACT_APP_APP_NAME}, todos los derechos reservados.`,
              privacy_policies: 'Políticas de privacidad',
              terms_and_conditions: 'Términos y condiciones', // "tos_description"
              send_feedback_title: '¿Qué podríamos mejorar?', // "send_rate_title"
              send_feedback_thanks_title: '¡Muchas gracias!',
              send_feedback_thanks_content: 'Tu opinión es importante para nosotr@s',
              send_feedback_error_title: 'Ha ocurrido un error',
              send_feedback_error_content: 'No pudimos enviar tu comentario'
            },
            navbar: {
              items: {
                search: 'Búsqueda',
                lines: 'Líneas', // home_shortcut_title_lines
                card_refill: 'Recarga', // "card_refill"
                alt_info_uppercase: 'Información útil',
                info_uppercase: 'Info útil', // info_uppercase
                alt_alerts: 'Alertas y noticias',
                alerts: 'Alertas',
                share: 'Share'
              }
            },
            change_country: {
              title: 'Elige tu país', // "choose_your_country"
              autocomplete: {
                placeholder: 'Buscar'
              },
              alert: 'Debes seleccionar un país para continuar.'
            },
            change_city: {
              title: 'Elige tu ciudad', // "choose_your_city"
              autocomplete: {
                placeholder: 'Buscar'
              },
              alert: 'Debes seleccionar una ciudad para continuar.'
            },
            filters: {
              title: '¿Qué quieres ver en el mapa?',
              items: {
                refill_points: 'Bocas de recarga',
                user_reports: 'Reportes de usuarios',
                network_map: 'Mapa de red'
              }
            },
            places: {
              home: 'Casa', // "home"
              work: 'Trabajo', // "work"
              add_home: 'Agregar casa',
              add_work: 'Agregar trabajo',
              add_favorite_place: 'Agregar lugar favorito'
            },
            origin_or_destination: '¿Desde o hasta?', // "origin_or_destination"
            dialog_destination_title: 'Selecciona un destino o un punto de partida', // "dialog_destination_title"
            from_here: 'Desde aquí', // "from_here"
            to_here: 'Hacia aquí', // "to_here"
            short_from_here: 'Desde', // "from_here"
            short_to_here: 'Hacia', // "to_here"
            searching: 'Buscando...'
          },
          lines: {
            real_time: 'Tiempo real',
            header: {
              title: 'Todas las lineas' // android id = all_lines
            },
            autocomplete: {
              placeholder: 'Buscar',
              alternative_placeholder: 'Buscar una linea'
            },
            favorites: {
              title: 'Favoritos'
            },
            transport_types: {
              all: 'Todos'
            },
            helper_text: '🤔 Intenta con otro término de búsqueda',
            add_to_favorites: 'Añadir a favoritos',
            line_without_delays: '¡Línea sin demoras!',
            line_with_delays: 'Esta línea puede presentar demoras',
            max_visible_vehicles_onboarding: {
              title: 'Próximos arribos',
              description: 'Esta línea cuenta con tiempo real, puedes visualizar próximos arribos seleccionando un paradero en el recorrido.',
              button: 'Entendido'
            }
          },
          send: 'Enviar',
          hint_location_A: '¿Desde dónde sales?', // hint_location_A
          hint_location_B: '¿A dónde quieres ir?', // hint_location_B
          my_location: 'Mi ubicación', // "my_location"
          search: {
            my_location: 'Mi ubicación', // "my_location"
            pick_on_map: 'Elegir en el mapa', // "autocomplete_pick_on_map"
            help: 'Comenzá buscando tus lugares o destinos. Las búsquedas recientes aparecerán acá.',
            super_search: '¿No encuentras el lugar?', // "super_search"
            recent: 'Recientes', // recent
            delete_history: 'Borrar historial'
          },
          choose_from_map: {
            select_location: 'Selecciona una ubicación', // "select_location"
            confirm: 'Aceptar',
            favorite_place_success_title: '¡Lugar favorito agregado!',
            favorite_place_success_message: 'Ya podés acceder a este lugar desde la pantalla de inicio',
            favorite_place_error_title: 'Ha ocurrido un error',
            favorite_place_error_message: 'No pudimos agregar este lugar a tus favoritos',
            favorite_place_name_form_title: 'Nuevo lugar favorito',
            favorite_place_name_form_label: 'Nombre',
            favorite_place_cancel: 'Cancelar',
            add_favorite_place: 'Agregar lugar favorito',
            favorite_place_confirm: 'Guardar'
          },
          search_preview: {
            title: 'Confirmar búsqueda', // "previous_search_title_confirm_search"
            alt_title: 'Filtrar búsqueda',
            arrival_time_first_option: 'Salir ahora', // "arrival_time_first_option"
            arrival_time_second_option: 'Llegar a las', // "arrival_time_second_option"
            search: 'Buscar', // "search"
            filters: {
              title: 'Filtros de búsqueda', // "filter_search"
              items: {
                walk: 'Caminata', // "walk"
                blocks: 'Cuadras', // "blocks"
                type_route: 'Tipo de ruta', // "type_route"
                transfers: { // "transfers_array"
                  best_route: 'Mejor ruta',
                  only_directs: 'Solo directos',
                  only_transfers: 'Solo transbordos'
                },
                transport_types: 'Tipos de transporte', // "transport_types"
                filters: 'Filtros',
                remember_changes: 'Recordar para próximas búsquedas'
              },
              apply_and_search: 'Aplicar y buscar' // "apply_and_search"
            }
          },
          routes: {
            line_route: 'Línea {{prop1}}', // "line_route"
            choose_direction: 'Elija una dirección', // "choose_direction"
            see_times: 'Ver horarios', // "see_times"
            times: 'Horarios', // "times"
            data_provider: 'Datos provistos por {{prop1}}',
            data_source_notes: 'Es posible que la información de recorridos, paradas y horarios se vea alterada debido a cortes de calzada, condiciones climáticas, tráfico u otros factores. Recomendamos tener en cuenta estos aspectos al planificar tu viaje.', // "data_source_notes"
            reported_by: 'Reportado por GPS hace {{prop1}}',
            share: 'Compartir',
            send_to_mobile: 'Enviar a tu teléfono'
          },
          invitation_message: {
            title: '¡Descubriste una nueva función!',
            content: 'Descárgate la app y ¡pruébala!',
            ios_content: 'Estamos trabajando en esta nueva característica 🚀',
            download: 'Descargar app',
            link_error_message: 'Ha ocurrido un error. Por favor, inténtalo más tarde.'
          },
          news: {
            title: 'Alertas y Noticias',
            news: {
              title: 'Noticias',
              see_new: 'Ver noticia completa',
              load_more_news: 'Cargar más noticias',
              show_less: 'Mostrar menos'
            },
            alerts: {
              title: 'Alertas',
              possible_affected_routes: 'Posibles recorridos afectados', // "possible_affected_routes"
              load_more_alerts: 'Cargar más alertas',
              show_less: 'Mostrar menos'
            },
            creation_date: '{{prop1}} por ',
            see: 'Ver',
            cancel: 'Cancelar',
            no_results: 'No se encontraron resultados'
          },
          share: {
            title: 'Compartir',
            link: 'Enlace',
            social: 'Social',
            copy_link: 'Copiar enlace',
            copied: 'Copiado',
            visualize_this_trip: 'Visualiza este viaje en el link.',
            visualize_this_search: 'Visualiza esta búsqueda.',
            user_has_sent_you_a_search: '{{prop1}} te ha enviado una búsqueda!',
            they_sent_you_a_quest: '¡Te han enviado una búsqueda!',
            share_stop_preview_title: 'Mirá los arribos de esta parada' // "share_stop_preview_title"
          },
          share_route: 'Compartir recorrido',
          share_with_wpp: 'Compartir con WhatsApp',
          send_to_mobile: {
            title: 'Enviar a tu teléfono',
            address_prompt: 'Dirección: {{address}}', // "address_prompt"
            step_1: '1. Abre la cámara de tu dispositivo móvil y apunta para encuadrar el código QR.',
            step_2: '2. Enfoca para que lo reconozca.',
            step_3: `3. Pulsa en la notificación y listo: ¡Disfruta ${REACT_APP_APP_NAME} en tu móvil!`,
            help: '¿No puedes escanear el código? ',
            see_more_options: 'Inicia sesión y conoce las otras opciones'
          },
          send_by_wpp: 'Enviar por WhatsApp',
          stop: {
            stop_details_tab_title: 'Parada', // "stop_details_tab_title"
            stop_details_status_tab_title: 'Estado', // "stop_details_status_tab_title"
            not_reports: 'Esta parada no presenta reportes', // "not_reports" // TODO: review, in android Esta %1$s no presenta reportes
            arrival_time_info_green: 'Estimación del próximo arribo según la posición real de la unidad. La precisión puede variar según la señal GPS o imprevistos en el viaje. ¡A medida que crece la comunidad de usuarios mejoramos la exactitud del dato!', // "arrival_time_info_green"
            arrival_time_info_yellow: 'Estimación del próximo arribo basada en los horarios que informan las empresas o el municipio en sus sitios públicos. Estos tiempos se actualizan regularmente y pueden no ser exactos.', // "arrival_time_info_yellow"
            report: 'Reportar', // "report"
            scheduled: 'Programado', // "scheduled"
            see_times: 'Horarios', // "see_times"
            understood: 'Entendido', // "understood"
            take_picture: 'Tomar foto', // "take_picture"
            more_pictures: 'Más fotos', // "more_pictures"
            arrivals: {
              title: 'Próximos arribos', // "next_arrivals"
              arriving: 'Arribando...' // "arriving"
            },
            options: {
              stop_details_option_show_in_street_view: 'Ver en Street View', // "stop_details_option_show_in_street_view"
              stop_details_option_add_picture: 'Agregar foto', // "stop_details_option_add_picture"
              edit: 'Editar', // "edit"
              report: 'Reportar', // "report"
              share: 'Compartir' // "share"
            },
            stop_secure: '¿Esta parada es segura?', // "stop_secure"
            arrival_times_text: '¿Cómo obtenemos los tiempos de arribo?', // "arrival_times_text"
            arrivals_unavailable: 'Los arribos de esta parada no disponibles en este momento',
            watch: 'Ver', // "watch"
            no: 'No', // "no"
            yes: 'Si', // "yes"
            why: '¿Por qué?', // "why"
            estimated_occupancy: 'Ocupación estimada', // "estimated_occupancy"
            occupancy_dialog_desc_2: 'Puedes compartir el nivel de ocupación desde el modo en viaje', // "occupancy_dialog_desc_2"
            occupancy_dialog_desc_3: 'Nivel de ocupación estimado para este arribo', // "occupancy_dialog_desc_3"
            feedback: {
              bar_title: '¿Algo mal con esta parada?',
              result_detail_button_text: '¿Algo mal con esta parada?',
              form_send_button_text: 'Enviar'
            }
          },
          stop_times: {
            day_tabs: {
              week_days: 'Días de semana',
              saturday: 'Sábado',
              sunday_and_holydays: 'Domingos y feriados'
            },
            share: {
              title: `Planificá tu viaje en ${REACT_APP_APP_NAME}`,
              description: 'Horarios del recorrido {{tripHeadSign}} para la parada {{stopName}}',
              short_title: 'Compartir parada',
              short_description: `Consultá esta parada en ${REACT_APP_APP_NAME}`
            }
          },
          user: {
            my_account: 'Mi cuenta',
            level: 'Nivel', // "level",
            scores: 'Puntos', // "scores"
            ranking: 'Ranking', // "ranking"
            missions: 'Misiones', // "missions"
            my_avatar: 'Mi avatar', // "my_avatar"
            settings: 'Ajustes',
            no_recent_activity: 'No se encontró actividad reciente',
            recent_activity: 'Actividad reciente', // "recent_activity"
            personal_information: {
              title: 'Información personal',
              account_data_title: 'Datos de cuenta',
              email: 'E-mail',
              user: 'Usuario',
              personal_data_title: 'Datos personales',
              name_and_surname: 'Nombre y apellido',
              telephone: 'Teléfono',
              gender: 'Género',
              genders: {
                male: 'Masculino',
                female: 'Femenino',
                prefer_not_to_say: 'Prefiero no decirlo'
              }
            },
            options: {
              become_a_contributor: 'Convertirme en colaborador', // "become_a_contributor"
              why_sum_points: 'Por qué sumar puntos', // "why_sum_points"
              sign_out: 'Cerrar sesión', // "sign_out"
              delete_account: 'Eliminar cuenta' // "delete_account"
            }
          },
          logout_confirmation: {
            title: '¿Estás seguro que deseas cerrar sesión?', // "logout_confirmation",
            no: 'No', // "no"
            yes: 'Si' // "yes"
          },
          delete_account_first_confirmation: {
            title: `Estás por eliminar tu cuenta de ${REACT_APP_APP_NAME}, ¿estás segur@?`,
            no: 'No',
            yes: 'Si'
          },
          delete_account_second_confirmation: {
            title: 'Si aceptas eliminar tu cuenta se borrará definitivamente el registro de puntos obtenidos por colaborar con la Comunidad y cada uno de tus aportes/ contribuciones. Además s eliminarán  tus líneas, paradas y lugares favoritos.', // "delete_account_second_confirmation",
            no: 'Cancelar',
            yes: 'Aceptar'
          },
          delete_account_failure: {
            title: 'No se pudo eliminar tu cuenta',
            message: 'Por favor, intenta nuevamente'
          },
          deep_links: {
            title: '¡Descarga la app!',
            content: `${REACT_APP_APP_NAME} es la app colaborativa que necesitas para moverte más fácil, rápido y seguro en tu ciudad en transporte público o cualquier alternativa al auto.\n' + 'Encuentra los recorridos, rutas, estaciones y paradas, horarios y demoras de todas las líneas de tu ciudad.`,
            download: 'Descargar'
          },
          login: {
            title: 'Iniciar sesión o registrarse',
            social_button_title: 'Iniciar sesión con {{prop1}}',
            phone_button_title: 'Iniciar sesión con número de teléfono',
            email_button_title: 'Iniciar sesión con e-mail',
            phone_number_title: 'Ingresa tu número de teléfono',
            phone_number_input_label: 'Teléfono',
            continue_button: 'Continuar',
            cancel_button: 'Cancelar',
            code_verification_title: 'Ingresa el código de verificación que te enviamos a {{prop1}}',
            countdown_text: 'Reenviar código en {{prop1}} segundos',
            resend_code_button: 'Reenviar código',
            resend_code_success_alert_title: 'Código reenviado a {{prop1}}',
            name_form_title: 'Ingresa tu nombre',
            name_input_label: 'Nombre',
            email_input_label: 'E-mail',
            email_is_required: 'El e-mail es requerido',
            password_is_required: 'La contraseña es requerida',
            passwords_not_match: 'Las contraseñas no coinciden',
            name_is_required: 'El nombre es requerido',
            input_default_error: 'Por favor, revisá los datos ingresados',
            first_password_input_label: 'Contraseña',
            second_password_input_label: 'Repetir contraseña',
            create_account_button: 'Crear cuenta',
            accept: 'Si continúas, confirmas que aceptas nuestros ',
            terms_and_conditions: 'términos y condiciones',
            and_our: ' y nuestra ',
            privacy_policy: 'política de privacidad. ',
            email_not_verified: 'Tu correo electrónico no está verificado.',
            resend_email: 'Reenviar email',
            email_verification_success_title: 'E-mail verificado',
            email_verification_success_message: 'Tu e-mail ha sido verificado exitosamente.',
            email_verification_failure_title: 'Error al verificar e-mail',
            email_verification_failure_message: 'No se pudo verificar tu e-mail. Por favor, intenta nuevamente.',
            failure_login_title: 'Error al iniciar sesión',
            failure_login_message: 'No se pudo iniciar sesión. Por favor, intenta nuevamente.',
            fee_warning: 'Es posible que se apliquen cargos en la cuenta de tu dispositivo móvil.',
            password_min_length: 'La contraseña debe tener al menos 6 caracteres',
            modal: {
              title: 'Iniciar sesión', // "login"
              login_benefits_1: 'Parece que esta característica requiere que inicies sesión. ', // "login_benefits"
              login_benefits_2: 'Obtendrás acceso a todas las funcionalidades, recibirás alertas personalizadas y mantendremos tus datos sincronizados.', // "login_benefits"
              later: 'Luego' // "later"
            }
          },
          trip_share: {
            results: 'Rutas y trayectos', // "results"
            recommended_routes_title: 'Rutas recomendadas', // "recommended_routes_title"
            search_result_fare_title: 'Precio', // "search_result_fare_title"
            search_result_arrival_title: 'Llegada', // "search_result_arrival_title"
            search_result_crowded_average_title: 'Ocupación', // "search_result_crowded_average_title"
            button_load_more_search_result: 'Cargar más resultados', // "button_load_more_search_result"
            button_load_fewer_search_results: 'Mostrar menos resultados',
            car_result_distance_title: 'Distancia', // "car_result_distance_title"
            available_title: 'Disponibles',
            alternatives: 'Transporte alternativo', // "alternatives"
            passengers_on_board_title: 'Pasajeros a bordo',
            occupancy_item_title_1: 'Muy pocos', // occupancy_item_title_1,
            occupancy_item_title_2: 'Pocos', // occupancy_item_title_2
            occupancy_item_title_3: 'Muchos', // occupancy_item_title_3
            occupancy_item_title_4: 'Lleno', // occupancy_item_title_4
            could_not_find_results: 'No se encontraron resultados\n' + 'Prueba modificando tus preferencias de búsqueda',
            feedback: {
              bar_title: '¿Algo mal con los resultados?',
              result_detail_button_text: '¿Algo mal con este viaje?'
            },
            see_full_route: 'Ver ruta completa',
            bike_station: 'Estación de bicicletas',
            bikes: 'Bicicletas',
            places: 'Lugares',
            open: 'Abrir',
            walk_to: 'Caminar hasta',
            you_are_aprox: 'Estás a aprox. {{prop1}} m', // "you_are_aprox"
            wait_for: 'Esperar a',
            go_forward: 'Avanzar {{prop1}} paradas',
            close_travel_mode_dialog_title: '¡Aún no llegas!',
            close_travel_mode_dialog_content: 'Continúa en el asistente para conocer cuando finaliza tu recorrido',
            close_travel_mode_dialog_confirm_text: 'Salir',
            close_travel_mode_dialog_cancel_text: 'Permanecer',
            see_all_stops: 'Ver todas las paradas',
            walk: 'Caminar',
            trip_duration_title: 'Tiempo de viaje',
            moto_taxi: {
              title: 'Ruta más rápida',
              call_to_action_button_text: 'Pedir',
              suggested_price: 'Precio sugerido',
              pick_up_drop_off_diferent_zones_warning: 'Tu destino se encuentra en una zona distinta a tu zona de recogida',
              no_drop_off_zone_covered_warning: 'Tu destino se encuentra fuera de la zona de cobertura',
              no_pick_up_zone_covered_warning: 'Tu punto de recogida se encuentra fuera de la zona de cobertura'
            }
          },
          results: {
            start: 'Iniciar', // "start"
            you_arrive_at: '🏁 Llegarás a las' // "you_arrive_at"
          },
          identify_city: {
            title: 'Parece que estás en {{prop1}}', // "change_city"
            content: '¿Deseas moverte a esta ciudad?', // "change_city_text"
            i_disagree: 'No, gracias', // "i_disagree"
            yes: 'Si'
          },
          change_city: {
            title: 'Seleccioná tu ciudad'
          },
          covid: {
            attention_schedule: 'Horarios de atención:',
            more_information: 'Más información'
          },
          languages: {
            language: 'Idioma',
            english: 'Inglés',
            spanish: 'Español {{country}}'
          },
          smart_banners: {
            full_page: {
              title: 'La única App colaborativa de transporte público para tus viajes urbanos',
              subtitle: '¡No pierdas más tiempo!',
              app_dowloads: '({{prop1}} descargas)',
              download_app: 'Descargar app',
              continue_on_mobile_site: 'Continuar en el sitio móvil'
            },
            top: {
              title: 'Sigue tu viaje en la App',
              open: 'Abrir'
            },
            bottom: {
              title: '¡Más fácil con la App!',
              subtitle: 'La comunidad de usuarios colabora con tu viaje en vivo',
              use_app: 'Usar la app'
            }
          },
          loading: 'Cargando...',
          feedback_on_boarding_modal: {
            title: `¿Estás satisfecho con los datos de ${REACT_APP_APP_NAME}?`,
            description_1: 'Tu feedback nos interesa. Si algo salió mal déjanoslo saber para que podamos mejorar tu experiencia.',
            description_2: 'Puedes compartir con nosotros tus inquietudes cada vez que veas un botón con este icono:',
            button_text: 'Entendido'
          },
          feedback_form: {
            button_text: 'Enviar feedback',
            general_error_message: 'Hubo un error al enviar el feedback. Por favor, intentá nuevamente.',
            no_questions_error_title: 'Ha ocurrido un error',
            no_questions_error_message: 'Inténtalo nuevamente más tarde.'
          },
          feedback_success_modal: {
            title: '¡Gracias por tu feedback!',
            description: 'Vamos a tomarlo en cuenta para mejorar tu experiencia.'
          },
          city_info: {
            no_results: 'No se encontraron resultados'
          },
          nps_survey: {
            title: '¿Qué tan probable es que recomiendes este planificador de viajes a tus amigos o familiares?',
            low_label: 'Nada probable',
            high_label: 'Muy probable',
            text_area_placeholder: '¿Por qué?',
            button_text: 'Enviar'
          },
          tickets_rewards_program: {
            nav_bar_button: 'Suma puntos',
            reward_tickets_page_title: 'Suma puntos',
            reward_tickets_page_hero_title: 'Gana con tus viajes',
            reward_tickets_page_hero_description: '¡Bienvenido al programa de beneficios Treep! Registra el boleto de cada uno de tus viajes y participa por increíbles premios. En este lanzamiento, nos acompañan las siguientes empresas:',
            uploaded_tickets: 'boletos registrados',
            points: 'Puntos',
            redeem_points: 'Canjear puntos',
            add_new_ticket: 'Cargar nuevo boleto',
            add_points: 'Sumar puntos',
            faq_title: 'Preguntas frecuentes',
            reward_tickets_validate_page_title: 'Cargar nuevo boleto',
            validate_code: 'Validar código',
            select_company: 'Selecciona una empresa',
            select_price: 'Selecciona un monto',
            select_company_error: 'Debes seleccionar una empresa',
            select_price_error: 'Debes seleccionar un monto',
            select_ticket_number_error: 'Debes ingresar un número de boleto',
            select_image_error: 'Debes subir una imagen',
            faqs: {
              question_1: '¿Cómo cargo mi boleto?',
              answer_1: 'Selecciona “Cargar nuevo boleto” y sigue las instrucciones según la empresa de transporte para validar tu boleto.',
              question_2: 'No puedo registrar mi boleto',
              answer_2: 'Revisa que todos los campos de información esten completos correctamente y asegúrate de no haber validado el boleto con anterioridad.',
              question_3: 'Mi boleto ya ha sido registrado',
              answer_3: 'Si tu boleto figura como registrado y no fuiste tu,  envianos un mail a contacto@treep.pe, comentándonos tu usuario y adjuntando una foto de tu boleto.',
              question_4: '¿Cómo canjeo mis puntos?',
              answer_4: 'Cada empresa pronto compartirá cuáles serán los premios que entregará a sus usuarios. Comienza a sumar puntos así eres uno de los primeros en canjearlos!'
            },
            reward_tickets_validate_page_hero_title: 'Encuentra el número y precio en tu boleto',
            company_label: 'Empresa',
            price_label: 'Precio del boleto',
            ticket_number_label: 'Número de boleto',
            example: 'Ejemplo',
            validation_component: {
              points_earned: 'Has ganado {{points}} puntos',
              success_button: 'Ver mis puntos',
              error_button: 'Volver al formulario'
            },
            image_upload_component: 'Toma una foto de tu boleto o selecciona una foto de tu galería',
            unsave_changes_confirmation_title: 'Cambios no guardados',
            unsave_changes_confirmation_content: '¿Estás seguro de que deseas salir de esta página? Se perderán tus cambios.',
            unsave_changes_confirmation_cancel_button: 'Cancelar',
            unsave_changes_confirmation_leave_button: 'Salir',
            see_active: 'Ver cupón activo',
            active_coupon: 'Cupón activo',
            redeemed_coupon: 'Cupón utilizado'
          },
          otp_directions: {
            DEPART: 'Dirígete al {{absoluteDirection}} hacia {{streetName}}',
            CONTINUE: 'Continúa en {{streetName}}',
            LEFT: 'Gira a la izquierda en {{streetName}}',
            SLIGHTLY_LEFT: 'Gira ligeramente a la izquierda en {{streetName}}',
            HARD_LEFT: 'Gira bruscamente a la izquierda en {{streetName}}',
            RIGHT: 'Gira a la derecha en {{streetName}}',
            SLIGHTLY_RIGHT: 'Gira ligeramente a la derecha en {{streetName}}',
            HARD_RIGHT: 'Gira bruscamente a la derecha en {{streetName}}',
            CIRCLE_CLOCKWISE: 'En la rotonda, toma la {{exit}} salida en dirección a {{streetName}}',
            CIRCLE_COUNTERCLOCKWISE: 'En la rotonda, toma la {{exit}} salida en dirección a {{streetName}}',
            ELEVATOR: 'Toma el ascensor y bájate en {{streetName}}',
            UTURN_LEFT: 'Haz un cambio de sentido a la izquierda en {{streetName}}',
            UTURN_RIGHT: 'Haz un cambio de sentido a la derecha en {{streetName}}',
            SOUTH: 'sur',
            SOUTHWEST: 'suroeste',
            SOUTHEAST: 'sureste',
            NORTH: 'norte',
            NORTHEAST: 'noreste',
            NORTHWEST: 'noroeste',
            WEST: 'oeste',
            EAST: 'este',
            1: 'primera',
            2: 'segunda',
            3: 'tercera',
            4: 'cuarta',
            5: 'quinta',
            6: 'sexta',
            7: 'séptima',
            8: 'octava'
          },
          earn_options: {
            title: 'Cómo ganar puntos',
            description: 'Aprenda a obtener puntos con acciones.',
            next_button: 'Siguiente',
            back_button: 'Anterior',
            coming_soon: 'Próximamente'
          }
        }
      },
      'es-CL': {
        translation: {
          metadata: {
            description: REACT_APP_HARDCODED_DESCRIPTION_ES_CL || 'Cómo llegar, líneas, recorridos, paraderos horarios, saldo y chat con la comunidad de usuarios de buses, metros, trenes y todo el transporte público.',
            title: '{{company}} - Recorridos y horarios de buses, metros y trenes'
          },
          home: {
            manu: {
              download_app: '¡Descarga la App!'
            },
            dialog_destination_title: '¿Hasta?' // "dialog_destination_title"
          },
          lines: {
            autocomplete: {
              alternative_placeholder: 'Buscar un servicio'
            }
          },
          hint_location_A: 'Dirección o lugar', // hint_location_A
          search_preview: {
            filters: {
              items: {
                walk: 'A pie' // "walk"
              }
            }
          },
          trip_share: {
            search_result_fare_title: 'Costo', // "search_result_fare_title"
            go_forward: 'Avanzar {{prop1}} paraderos',
            see_all_stops: 'Ver todos los paraderos'
          },
          routes: {
            choose_direction: 'Elija un sentido', // "choose_direction"
            see_times: 'Horarios', // "see_times"
            data_source_notes: 'Es posible que la información de recorridos, paraderos y horarios se vea alterada debido a cortes de calle, condiciones climáticas, tráfico u otros factores. Recomendamos tener en cuenta estos aspectos al planificar tu viaje.' // "data_source_notes"
          },
          invitation_message: {
            content: 'Descarga la App y ¡pruébala!'
          },
          share: {
            share_stop_preview_title: 'Mira los arribos de este paradero' // "share_stop_preview_title"
          },
          stop: {
            arrival_time_info_yellow: 'Estimación del próximo arribo basada en los horarios que informan las empresas o las comunas en sus sitios públicos. Estos tiempos se actualizan regularmente y pueden no ser exactos.', // "arrival_time_info_yellow"
            not_reports: 'Este paradero no presenta reportes', // "not_reports" // TODO: review, in android Esta %1$s no presenta reportes
            stop_details_tab_title: 'Paradero', // "stop_details_tab_title"
            see_times: 'Horarios', // "see_times"
            stop_secure: '¿Este paradero es seguro?' // "stop_secure"
          },
          user: {
            settings: 'Configuración'
          },
          news: {
            see: 'Ver más'
          }
        }
      },
      'es-PE': {
        translation: {
          tickets_rewards_program: {
            nav_bar_button: 'Suma puntos',
            reward_tickets_page_title: 'Suma puntos'
          }
        }
      }
    }
  })

export default i18n
