import React, { useEffect, useState, useRef } from 'react'
import { RecommendedRoutesContainer } from '../../../GlobalStyles'
import ResultItem from '../../ResultItem'
import { useTripResults } from '../../../hooks/useTripResults'
import {
  ShowLessIcon,
  ShowMoreContainer,
  ShowMoreIcon,
  ShowMoreText
} from '../../../components/SearchResults/styles'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import NoResultMessage from '../../NoResultMessage'
import { getAuth } from 'firebase/auth'
import moment from 'moment'
import _ from 'lodash'
import useQuery from '../../../hooks/useQuery'
import { getUrlRealTimeByCity } from '../../../utils'
import { getTripUpdates } from '../../../db/getTripUpdates'
import { getArrivalsRealTime } from '../../../db/getArrivalsRealTime'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfig } from '../../../firebase/firebase-config'

export const RecommendedRoutes = ({
  showMore,
  setShowMore,
  itineraries,
  setItineraries
}) => {
  const { t } = useTranslation()
  const auth = getAuth()
  const user = auth.currentUser
  const query = useQuery()
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(true)
  const [itinerariesWithRealTime, setItinerariesWithRealTime] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [hasFetchedRealTime, setHasFetchedRealTime] = useState(false)

  const {
    handleTripClick
  } = useTripResults()

  const loading = useSelector(state => state?.ui?.loading)
  const tripResponse = useSelector(state => state?.ui?.tripResponse)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const intervalRef = useRef(null)

  useEffect(() => {
    if (!itineraries || itineraries.length === 0) return
    // Fetch real-time data for itineraries
    fetchRealTimeDataForItineraries()
    // eslint-disable-next-line
  }, [itineraries])

  useEffect(() => {
    if (itinerariesWithRealTime.length === 0) return

    // Set up interval to update real-time data every 30 seconds
    intervalRef.current = setInterval(() => {
      updateRealTimeData()
    }, 30000)

    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalRef.current)
    }
    // eslint-disable-next-line
  }, [itinerariesWithRealTime])

  const updateRealTimeData = async () => {
    const updatedItineraries = await Promise.all(itinerariesWithRealTime.map(async (itinerary) => {
      const updatedItinerary = await fetchRealTimeDataForItinerary(itinerary)
      return updatedItinerary
    }))

    // Keep the itineraries in their original order
    setItinerariesWithRealTime(updatedItineraries)
  }

  const fetchRealTimeDataForItineraries = async () => {
    setIsLoading(true)
    const updatedItineraries = await Promise.all(itineraries.map(async (itinerary) => {
      const updatedItinerary = await fetchRealTimeDataForItinerary(itinerary)
      return updatedItinerary
    }))
    // Sort the itineraries based on real-time first, then scheduled time
    await fetchAndActivate(remoteConfig)
    const sortByRealTime = getValue(remoteConfig, 'order_results_by_rt')
    let sortedItineraries = updatedItineraries

    if (cityConfig.config.realtime_hub_enabled && sortByRealTime._value === 'true') {
      sortedItineraries = sortItineraries(updatedItineraries)
    }
    setItinerariesWithRealTime(sortedItineraries)
    setHasFetchedRealTime(true)
    setIsLoading(false)
  }

  const fetchRealTimeDataForItinerary = async (itinerary) => {
    // Deep clone the itinerary to avoid mutating the original
    const itineraryClone = _.cloneDeep(itinerary)
    if (!itineraryClone) return itineraryClone

    const firstLegNoWalk = itineraryClone?.legs?.find(item => item.mode !== 'WALK')

    if (!firstLegNoWalk) {
      return itineraryClone
    }

    let realTime = null
    let schedule = moment(firstLegNoWalk?.startTime).format('HH:mm')

    if (firstLegNoWalk?.realTime && firstLegNoWalk?.realtimeArrival > 0 && !JSON.parse(query.get('timestamp'))) {
      const minutes = Math.floor(firstLegNoWalk?.realtimeArrival / 60000)
      realTime = { minutes }
      // Add realTime and schedule to the cloned itinerary
      itineraryClone.realTime = realTime
      itineraryClone.schedule = schedule
      return itineraryClone
    }

    if (!cityConfig?.city_id) {
      console.error('Error getting trip realtime. No cityConfig provided.')
      return itineraryClone
    }

    const realTimeData = await getSearchResultsRealtime(firstLegNoWalk)

    realTime = realTimeData.realTime
    schedule = realTimeData.schedule

    // Add realTime and schedule to the cloned itinerary
    itineraryClone.realTime = realTime
    itineraryClone.schedule = schedule

    return itineraryClone
  }

  const getSearchResultsRealtime = async (firstLegNoWalk) => {
    let realTime = null
    let schedule = null

    if (!firstLegNoWalk || JSON.parse(query.get('timestamp'))) {
      return { realTime, schedule }
    }

    const { transportTypeId } = firstLegNoWalk

    const [busUrl, subwayUrl] = getUrlRealTimeByCity(cityConfig?.city_id, {
      stop_code: firstLegNoWalk?.from?.stopId,
      route_id: firstLegNoWalk?.routeId
    })

    const controller = new AbortController()
    const signal = controller.signal

    setTimeout(() => {
      controller.abort()
    }, 5000)

    try {
      if (cityConfig.config?.realtime_hub_enabled) {
        const response = await getTripUpdates(cityConfig.city_id, firstLegNoWalk?.from?.stopId, signal)
        const realtimeResult = response?.result?.find(item => item.routeId === firstLegNoWalk?.routeId && item.headsign === firstLegNoWalk.headsign)

        const minutes = realtimeResult?.realtime && realtimeResult?.realtimeArrival > 0 ? Math.floor(realtimeResult?.realtimeArrival / 60000) : null

        if (minutes) {
          realTime = { minutes }
        } else {
          realTime = null
          schedule = realtimeResult?.scheduledArrival
            ? moment(realtimeResult.scheduledArrival).format('HH:mm')
            : moment(firstLegNoWalk?.startTime).format('HH:mm')
        }
      } else if (cityConfig.config?.realtime_arrivals_enabled) {
        const params = {
          city_id: cityConfig.city_id,
          url: cityConfig.city_id === 21 && transportTypeId === 3 ? subwayUrl : busUrl,
          api_buenos_aires: cityConfig.city_id === 21
        }

        if (cityConfig.city_id === 22) { // Santiago de Chile, Chile
          if (transportTypeId !== 1) {
            return { realTime, schedule }
          }
          const token = await user.getIdToken()
          const response = await fetch(busUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`,
              'variant-id': process.env.REACT_APP_VARIANT_ID
            },
            signal
          })
          if (response.ok) {
            const data = await response.json()
            const result = data?.result?.entities?.item?.filter(item => item?.horaprediccionbus1)
            if (result?.length > 0 && result[0]?.horaprediccionbus1) {
              realTime = { minutes: result[0]?.horaprediccionbus1.replace(' min.', "'") }
            } else {
              const tripUpdates = await getTripUpdates(cityConfig.city_id, firstLegNoWalk?.from?.stopId)
              const realtimeResult = tripUpdates?.result?.find(item => item.routeId === firstLegNoWalk?.routeId && item.headsign === firstLegNoWalk.headsign)
              realTime = null
              schedule = realtimeResult?.scheduledArrival
                ? moment(realtimeResult.scheduledArrival).format('HH:mm')
                : moment(firstLegNoWalk?.startTime).format('HH:mm')
            }
          } else {
            throw new Error('Error getting realtime')
          }
        } else {
          const response = await getArrivalsRealTime(params, signal)
          let minutes = false
          let arrivalClosest = []
          let arrivalFiltered = []

          switch (cityConfig.city_id) {
            case 30: // Montevideo, Uruguay

              if (JSON.stringify(response).includes('mensaje') || JSON.stringify(response).includes('No existe') || transportTypeId !== 1) {
                return { realTime, schedule }
              }

              arrivalClosest = response
                ?.filter(item => item?.variante.toString() === firstLegNoWalk?.shapeId && item?.real)
                ?.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0))[0]

              if (!arrivalClosest) {
                return { realTime, schedule }
              }

              minutes = getMinutesFromNow(arrivalClosest.time)

              break
            case 29: // Mendoza, Argentina

              if (response?.code !== 200) {
                return { realTime, schedule }
              }

              arrivalClosest = response?.data?.entry.arrivalsAndDepartures
                ?.filter(arrival => arrival?.predicted && arrival?.routeId?.substring(arrival.routeId.indexOf('_') + 1, arrival.routeId.length) === firstLegNoWalk?.routeId?.toString())
                ?.sort((a, b) => (a?.scheduledArrivalTime > b?.scheduledArrivalTime) ? 1 : ((b?.scheduledArrivalTime > a?.scheduledArrivalTime) ? -1 : 0))[0]

              if (!arrivalClosest) {
                return { realTime, schedule }
              }

              minutes = getMinutesFromNow(arrivalClosest?.scheduledArrivalTime)

              break
            case 21: // Buenos Aires, Argentina

              if (transportTypeId === 1) { // Bus
                if (response.code !== 200) {
                  return { realTime, schedule }
                }

                arrivalFiltered = response?.data?.entry?.arrivalsAndDepartures?.filter((arrival) => {
                  const routeName = arrival?.routeShortName || arrival?.routeLongName
                  const tripRouteName = firstLegNoWalk?.routeShortName || firstLegNoWalk?.routeLongName
                  return arrival?.predicted === true && routeName === tripRouteName
                })

                if (arrivalFiltered.length === 0) {
                  return { realTime, schedule }
                }

                arrivalClosest = _.orderBy(arrivalFiltered, ['predictedArrivalTime'], ['asc'])?.map(item => {
                  return getMinutesFromNow(item?.predictedArrivalTime)
                })?.filter(item => item !== false)

                minutes = arrivalClosest?.length > 0 ? arrivalClosest[0] : false
              } else if (firstLegNoWalk?.transportTypeId === 3 && firstLegNoWalk?.from?.stopId && firstLegNoWalk?.routeId) {
                const stopId = firstLegNoWalk?.from?.stopId?.includes(':') ? firstLegNoWalk?.from?.stopId?.split(':')[1] : firstLegNoWalk?.from?.stopId
                arrivalFiltered = response?.Entity?.find((entity) => entity?.Linea?.Estaciones?.some(station => station?.stop_id === stopId))
                if (!arrivalFiltered) {
                  return { realTime, schedule }
                }

                minutes = getMinutesFromNow(arrivalFiltered.Linea?.Estaciones?.find(station => station.stop_id === stopId)?.arrival?.time * 1000)
              } else {
                return { realTime, schedule }
              }

              break
            default:
              return { realTime, schedule }
          }

          if (minutes) {
            realTime = { minutes }
          } else {
            const tripUpdates = await getTripUpdates(cityConfig.city_id, firstLegNoWalk?.from?.stopId)
            const realtimeResult = tripUpdates?.result?.find(item => item.routeId === firstLegNoWalk?.routeId && item.headsign === firstLegNoWalk.headsign)
            realTime = null
            schedule = realtimeResult?.scheduledArrival
              ? moment(realtimeResult.scheduledArrival).format('HH:mm')
              : moment(firstLegNoWalk?.startTime).format('HH:mm')
          }
        }
      } else {
        // Realtime not enabled
      }
    } catch (e) {
      console.error(e)
      realTime = null
      schedule = moment(firstLegNoWalk?.startTime).format('HH:mm')
    }

    return { realTime, schedule }
  }

  const getMinutesFromNow = (realtime) => {
    const now = moment()
    const arrival = moment(realtime)
    const diff = arrival.diff(now, 'minutes')

    if (diff > 0) {
      return diff
    } else {
      return false
    }
  }

  const sortItineraries = (itineraries) => {
    return itineraries.sort((a, b) => {
      const aRealTime = a.realTime?.minutes || Infinity
      const bRealTime = b.realTime?.minutes || Infinity

      if (aRealTime !== bRealTime) {
        return aRealTime - bRealTime
      } else {
        const aScheduleTime = moment(a.schedule, 'HH:mm').isValid() ? moment(a.schedule, 'HH:mm').unix() : Infinity
        const bScheduleTime = moment(b.schedule, 'HH:mm').isValid() ? moment(b.schedule, 'HH:mm').unix() : Infinity

        return aScheduleTime - bScheduleTime
      }
    })
  }

  const handleShowMore = () => {
    setShowMore(!showMore)

    if (!showMore) {
      setItineraries(tripResponse?.itineraries)
      setItinerariesWithRealTime([])
      setHasFetchedRealTime(false)
    } else {
      window.scrollTo(0, 0)
      setItineraries(tripResponse?.itineraries?.slice(0, 3))
      setItinerariesWithRealTime([])
      setHasFetchedRealTime(false)
    }
  }

  return (
    <RecommendedRoutesContainer>
      {tripResponse
        ? (
          <>
            {tripResponse?.itineraries?.length === 0
              ? (
                <NoResultMessage message={t('trip_share.could_not_find_results')} />
                )
              : (
                <>
                  {(itinerariesWithRealTime.length > 0 ? itinerariesWithRealTime : itineraries)?.map((trip, index) => {
                    const currentTip = trip?.legs?.find(leg => leg?.mode !== 'WALK')

                    return (
                      <ResultItem
                        disabled={loading}
                        key={index}
                        trip={trip}
                        onClickFunction={handleTripClick}
                        border
                        icons={false}
                        showOccupancyLevel={currentTip?.crowdedAverage > 0}
                        realTime={trip.realTime}
                        schedule={trip.schedule}
                      />
                    )
                  })}

                  {tripResponse?.itineraries?.length > 3 &&
                    <ShowMoreContainer
                      sx={{
                        width: '100%',
                        textTransform: 'none',
                        height: '48px'
                      }}
                      onClick={handleShowMore}
                    >
                      <ShowMoreText>  {showMore ? t('trip_share.button_load_fewer_search_results') : t('trip_share.button_load_more_search_result')}
                      </ShowMoreText>
                      {showMore ? <ShowLessIcon /> : <ShowMoreIcon />}
                    </ShowMoreContainer>}
                </>
                )}
          </>
          )
        : (
            [0, 1, 2].map((skeleton, index) => (
              <ResultItem
                disabled
                key={index}
                trip={null}
                border
                icons={false}
                showOccupancyLevel={false}
              />
            ))
          )}

    </RecommendedRoutesContainer>
  )
}
