import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Box, Button, Dialog, Menu, MenuItem, useMediaQuery } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import { CloseButton, CloseIcon, Container, CountriesList, Flag, Navbar, Title } from './style'
import { getAllCities } from '../../../db/getAllCities'
import { getDistanceFromLatLonInKm } from '../../../utils'
import {
  setAdsList,
  setCityAds,
  setCityIdentifiedDismissed,
  setCurrentCitySelected,
  setOpenCities,
  setOpenCountries
} from '../../../actions/ui'
import { logEvent } from '../../../firebase/firebase-config'
import { unsubscribeToCityTopics } from '../../../db/unsubscribeToCityTopics'
import { theme } from '../../../theme/theme'
import { getAdsByCity } from '../../../db/ads'
import maplibregl from 'maplibre-gl'
import { useMap } from 'react-map-gl'
import { setViewState } from '../../../actions/map'
import { setLocalCityConfig } from '../../../db/setLocalCityConfig'
import { getCityConfig } from '../../../db/getCityConfig'

export const CityChange = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { map } = useMap()
  const mobile = useMediaQuery('(max-width:480px)')

  const countries = useSelector(state => state?.ui?.latam?.countries)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const open = useSelector(state => state?.ui?.components?.openCountries)
  const userData = useSelector(state => state?.user?.userData)
  const fcmToken = useSelector(state => state?.user?.fcmToken)
  const auth = useSelector(state => state?.user.auth)

  const [currentCountry, setCurrentCountry] = useState()
  const [currentCities, setCurrentCities] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [city, setCity] = useState()

  const handleClickCity = async (city) => {
    try {
      setCity(city)
      setAnchorEl(null)
      setCurrentCountry(undefined)
      dispatch(setOpenCountries(false))

      const cityConfig = await getCityConfig(city?.city_id)
      setLocalCityConfig(cityConfig)

      getAdsByCity(cityConfig?.city_id)
        .then((response) => {
          if (response?.rotationTime?.some(item => item.ad_type_id === 20)) { // it means that there are some Search results advertising to show
            dispatch(setCityAds(response))
          } else {
            dispatch(setAdsList([]))
          }
        })

      dispatch(setOpenCities(false))

      dispatch(setCurrentCitySelected(cityConfig))

      dispatch(setCityIdentifiedDismissed(true))

      const eventParams = {
        lat: userPosition ? userPosition.lat : null,
        lng: userPosition ? userPosition.lng : null,
        last_city_id: cityConfig ? cityConfig.city_id : -1,
        last_city_name: cityConfig ? cityConfig.name : null,
        city_id: city?.city_id?.toString(),
        city_name: city?.name,
        city_distance: userPosition
          ? getDistanceFromLatLonInKm(
            userPosition.lat,
            userPosition.lng,
            city?.map_center_lat,
            city?.map_center_lng
          )
          : null,
        user_id: sessionStorage.getItem('uid'),
        user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
        user_gender: userData?.gender || null, // String
        os: 'web'

      }

      logEvent('change_city', eventParams)

      dispatch(setViewState({
        latitude: cityConfig.map.map_center_lat,
        longitude: cityConfig.map.map_center_lng,
        zoom: cityConfig.map.map_zoom_level
      }))

      const from = new maplibregl.LngLat(cityConfig.map.origin_marker_lng, cityConfig.map.origin_marker_lat)
      const to = new maplibregl.LngLat(cityConfig.map.destination_marker_lng, cityConfig.map.destination_marker_lat)

      map?.fitBounds(new maplibregl.LngLatBounds(from, to), {
        padding: {
          top: 80,
          left: mobile ? 16 : 482,
          right: 80,
          bottom: 80
        }
      })
    } catch (e) {
      console.error(e)
    }

    unsubscribeToCityTopics(cityConfig?.city_id, city?.city_id, fcmToken)
  }

  const handleClickCountry = async (e, country) => {
    setAnchorEl(e?.currentTarget)

    let lastCountryId = -1
    let lastCountryName = null

    if (cityConfig && cityConfig.country && cityConfig.country.country_id) {
      lastCountryId = cityConfig.country.country_id
      lastCountryName = cityConfig.country.name_es
    }

    const eventParams = {
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      last_country_id: lastCountryId,
      last_country_name: lastCountryName,
      country_id: country.country_id,
      country_name: country.name_es,
      user_id: auth.uid,
      os: 'web'
    }

    logEvent('change_country', eventParams)

    getAllCities(country?.country_id)
      .then((cities) => {
        if (userPosition) {
          const currentCities = cities?.map((city) => {
            const distance = getDistanceFromLatLonInKm(
              userPosition.lat,
              userPosition.lng,
              city?.map_center_lat,
              city?.map_center_lng
            )
            city.distance = parseInt(distance)
            return city
          })

          const citiesOrderByDistance = _.orderBy(currentCities, ['distance'], ['asc'])
          setCurrentCities(citiesOrderByDistance)
        } else {
          const citiesOrderByName = _.orderBy(cities, ['name'], ['asc'])
          setCurrentCities(citiesOrderByName)
        }
      })
    setCurrentCountry(country)
  }

  const handleClose = () => {
    if (city || cityConfig) {
      dispatch(setOpenCountries(false))
    } else {
      alert('Debes seleccionar una ciudad')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          backgroundColor: '#ffffff',
          outline: 'none !important',
          padding: '24',
          boxShadow: '0px 0px 24px #22283126',
          borderRadius: '16px'
        }
      }}
    >
      <Container>
        <Navbar>
          <Title>{t('change_city.title')}</Title>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Navbar>
        <CountriesList>
          {countries?.map((country, index) => {
            return (
              <Box key={index} style={{ position: 'relative', width: '50%' }}>
                <Button
                  key={country?.country_id}
                  sx={{
                    color: theme.text.primary.main,
                    width: '90%',
                    fontSize: '18px',
                    padding: '6px 8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}
                  onClick={(e) => handleClickCountry(e, country)}
                  startIcon={<Flag src={country?.flag_uri} />}
                  endIcon={<ArrowDropDown sx={{ color: '#757575' }} />}
                >
                  {country?.name_es}
                </Button>

                {country === currentCountry &&
                  <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setCurrentCountry(undefined)}
                  >
                    {currentCities?.map((city) => {
                      return (
                        <MenuItem
                          key={city?.city_id}
                          onClick={() => handleClickCity(city, map)}
                        >
                          {city?.name}
                        </MenuItem>
                      )
                    })}
                  </Menu>}
              </Box>
            )
          })}
        </CountriesList>
      </Container>
    </Dialog>
  )
}
